import categorybg1 from "../../images/1.jpg";
import categorybg2 from "../../images/2.jpg";
import categorybg4 from "../../images/lab/10.webp";
import categorybg6 from "../../images/lab/LOCKER.webp";

export const categories = [
  {
    id: 1,
    cid: 1,
    dropname:"Laboratory Furniture ⏷ ",
    name: "Laboratory Furniture",
    img1: require("../../images/lab/12.webp"),
    alink1:"/categorypage/111",
    img2: require("../../images/lab/48.webp"),
    alink2:"/categorypage/112",
    img3: require("../../images/lab/51.webp"),
    alink3:"/categorypage/113",
    // img4: require("../../images/lab/48.webp"),
    // img5: require("../../images/lab/49.webp"),
    // img7: require("../../images/lab/50.webp"),
    // img8: require("../../images/lab/51.webp"),
    cardImg:require("../../images/lab/12.webp"),
    cardDesc:"Elevate your laboratory environment with our high-quality, custom-designed laboratory furniture. We offer durable solutions that optimize functionality, safety, and aesthetics in industrial, educational, and healthcare settings.",
    // process:"SMARTLAB SERIES-01 ",
    content1:"laboratory furniture provides most economical and hygienic, in this type the furniture is supported directly on the floor and it will provide you more storage area than other type, this type of furniture is more advisable for hospital as the floor covering can be selected against the base of unit, ensuring clinical hygiene.",
    // content2:" 100mm high skirting (toe-kick) providing front side of base cabinets.",
    // content3:" The significant cost saving over a steel frame structure makes this the ideal system for pharmacy labs, computer rooms and storage rooms, even all the accessories are using high quality lab grade from various reputed suppliers same as other types",
    // content4:" Worktop & sink: To be selected to suit the application worktops are mounted on cabinet/support panel. ",
    // content5:" Service channel bridge: To be selected to suit the application.",
    // content6:" Color scheme	:  As per client choice. ",
    // content7:" Cabinetry: 	All the cabinets are mounted on attached pedestal of 100mm height",
    // process1:"SMARTLAB SERIES-02 ",
    // content10:"laboratory furniture provides better solution to meet most organization labs, in this type the furniture supported by a heavy duty could rolled mild steel rigid frame works, the frame epoxy powder coated at 80-micrones @ of 2000 temperature, the color of frame as per Clint's choice.",
    // content11:" Base frame fixed with an adjustable, nonabrasive foot will provide you perfect leveling even uneven surface, all base cabinet can be removed and rearranged with ease, the floor can easily clean since there is 14cm space available from the floor to frame work.",
    // content12:" This type is of system is most advisable for School & Industrial labs.",
    // content13:" Worktop & sink are mounted	: To be selected to suit the application worktops on cabinet/Panel. ",
    // content14:" Service channel bridge:  To be selected to suit the application. ",
    // content15:" Color scheme				:  As per client choice.  ",
    // content16:" Cabinetry					:  All the cabinets supported with metal frames.",
    // process2:"SMARTLAB SERIES-03 ",
    // content18:"laboratory furniture provides you more flexibility and easily can rearrange without deep external efforts, in type all the furniture and worktops are supported on a heavy duty cantilevered ‘C’ frame fitted with heavy duty adjustable, non-abrasive feet.",
    // content19:" To ensure the total flexibility/sliding of base cabinets, under bench cabinets are suspended on nylon brackets and guider that allow them to slide/move entire length of workbench without external tools.",
    // content20:" The cantilever frame connected with front frame and box type back frames provides sufficient strength entire the workbench and wall benches fixed to external wall.",
    // content21:" All base cabinets can be removed and rearranged with ease, the floor can easily clean since there is enough space available from the floor to cabinet.",
    // content22:" This type is of system is most advisable for University & professional laboratories.   ",
    // content23:" Service channel bridge:  To be selected to suit the application. ",
    // content25:" Color scheme				:  As per client choice.  ",
    // content24:" Cabinetry					:  All the cabinets supported with metal frames.",

    subcategory: [
		{ subid:111, 
				title: "SmartLab Series 01",
				images: [
					{
						img1: require("../../images/lab/12.webp"),
						img2: require("../../images/lab/13.webp"),
					},
				],
				description:
					"laboratory furniture provides most economical and hygienic, in this type the furniture is supported directly on the floor and it will provide you more storage area than other type, this type of furniture is more advisable for hospital as the floor covering can be selected against the base of unit, ensuring clinical hygiene.",
				list_items: [
					{
						content1:
							" 100mm high skirting (toe-kick) providing front side of base cabinets.",
						content2:
							" The significant cost saving over a steel frame structure makes this the ideal system for pharmacy labs, computer rooms and storage rooms, even all the accessories are using high quality lab grade from various reputed suppliers same as other types",
						content3:
							" Worktop & sink: To be selected to suit the application worktops are mounted on cabinet/support panel. ",
						content4:
							" Service channel bridge: To be selected to suit the application.",
						content5: " Color scheme	:  As per client choice. ",
						content6:
							" Cabinetry: 	All the cabinets are mounted on attached pedestal of 100mm height",
					},
				],
			},
		],
		subcategory2: [
			{ subid:112,
				title: "SmartLab Series 02",
				images: [
					{
						img1: require("../../images/lab/48.webp"),
						img2: require("../../images/lab/49.webp"),
					},
				],
				description:
					"laboratory furniture provides better solution to meet most organization labs, in this type the furniture supported by a heavy duty could rolled mild steel rigid frame works, the frame epoxy powder coated at 80-micrones @ of 2000 temperature, the color of frame as per Clint's choice.",
				list_items: [
					{
						content1:
							" Base frame fixed with an adjustable, nonabrasive foot will provide you perfect leveling even uneven surface, all base cabinet can be removed and rearranged with ease, the floor can easily clean since there is 14cm space available from the floor to frame work.",
						content2:
							" This type is of system is most advisable for School & Industrial labs.",
						content3:
							" Worktop & sink are mounted	: To be selected to suit the application worktops on cabinet/Panel. ",
						content4:
							" Service channel bridge:  To be selected to suit the application. ",
						content5: " Color scheme				:  As per client choice.  ",
						content6:
							" Cabinetry					:  All the cabinets supported with metal frames.",
					},
				],
			},
		],
		subcategory3: [
			{ subid:113,
				title: "SmartLab Series 03",
				images: [
					{
						img1: require("../../images/lab/50.webp"),
						img2: require("../../images/lab/51.webp"),
					},
				],
				description:
					"laboratory furniture provides you more flexibility and easily can rearrange without deep external efforts, in type all the furniture and worktops are supported on a heavy duty cantilevered ‘C’ frame fitted with heavy duty adjustable, non-abrasive feet.",
				list_items: [
					{
						content1:
							" To ensure the total flexibility/sliding of base cabinets, under bench cabinets are suspended on nylon brackets and guider that allow them to slide/move entire length of workbench without external tools.",
						content2:
							" The cantilever frame connected with front frame and box type back frames provides sufficient strength entire the workbench and wall benches fixed to external wall.",
						content3:
							" All base cabinets can be removed and rearranged with ease, the floor can easily clean since there is enough space available from the floor to cabinet.",
						content4:
							" This type is of system is most advisable for University & professional laboratories.   ",
						content5:
							" Service channel bridge:  To be selected to suit the application. ",
						content6: " Color scheme				:  As per client choice.  ",
						content7:" Cabinetry					:  All the cabinets supported with metal frames.",
					},
				],
			},
		],



    bgimg:require("../../images/lab/12.webp"),
    button: "know More",
    button1: "SMARTLAB SERIES-01",
    button2: "SMARTLAB SERIES-02",
    button3: "SMARTLAB SERIES-03",
  },
  {
    id: 2,
    cid:2,
    dropname:"Laboratory Fittings ⏷ ",
    name: "Laboratory Fittings",
    img2: require("../../images/lab/41.webp"),
    alink2:"/categorypage/120",
    img3: require("../../images/lab/42.webp"), 
    alink3:"/categorypage/121",
    img50: require("../../images/lab/43.webp"),
    alink50:"/categorypage/122",
    img1: require("../../images/lab/71.webp"),
    alink1:"/categorypage/119",
    firsth5:"WORKTOPS",
    secondh5:"WATER & GAS FITTINGS",
    thirdh5:"LABORATORY SINKS & ACCESSORIES",
    fourthh5:"LABORATORY SOCKETS",
    cardImg:require("../../images/cards/laboratoryfurniture.jpg"),
    cardDesc:"Enhance the efficiency and organization of your laboratory with our top-notch laboratory fittings. We provide a wide range of fittings designed to meet the specific needs of modern laboratories",
    content1:"Enhance the efficiency and organization of your laboratory with our top-notch laboratory fittings. We provide a wide range of fittings designed to meet the specific needs of modern laboratories, ensuring seamless workflow and compliance with industry standards.",
    shower1:"FLOOR MOUNTED EMERGENCY SHOWER WITH EYEWASH AND BOWL",
    shower2:"WALL MOUNTED EMERGENCY SHOWER WITH EYEWASH AND BOWL",
    shower3:"WALL MOUNTED EMERGENCY SHOWER",
    shower4:"FLOOR MOUNTED EMERGENCY EYEWASH",
    shower5:"WALL MOUNTED EMERGENCY EYEWASH",
    shower6:"TABLE  / WALL MOUNTED EMERGENCY EYEWASH",

    imgs1: require("../../images/lab/56.webp"),
    imgs2: require("../../images/lab/57.webp"),
    imgs3: require("../../images/lab/58.webp"),   
    imgs4: require("../../images/lab/59.webp"),  
    imgs5: require("../../images/lab/60.png"),  
    imgs6: require("../../images/lab/61.webp"),  
    subcategory:[
      {
      subid:118,
      title:"Emergency shower & eyewash",
      process1:"FLOOR MOUNTED EMERGENCY SHOWER WITH EYEWASH AND BOWL",
      process2:"WALL MOUNTED EMERGENCY SHOWER WITH EYEWASH AND BOWL",
      process3:"WALL MOUNTED EMERGENCY SHOWER",
      process4:"FLOOR MOUNTED EMERGENCY EYEWASH",
      process5:"WALL MOUNTED EMERGENCY EYEWASH",
      process6:"TABLE  / WALL MOUNTED EMERGENCY EYEWASH",
        
      img4: require("../../images/lab/56.webp"),
      img7: require("../../images/lab/57.webp"),
      img9: require("../../images/lab/58.webp"),   
      img11: require("../../images/lab/59.webp"),  
      img13: require("../../images/lab/60.png"),  
      img15: require("../../images/lab/61.webp"), 
    }
  ],
  subcategory2:[{
    subid:119,
    title:"Worktops",
    img1: require("../../images/lab/71.webp"),

  }],
  subcategory3:[{
    subid:120,
    title:"Water & gas fittings",
    img2: require("../../images/lab/41.webp"),


  }],
  subcategory4:[{
    subid:121,
    title:"Laboratory sinks & accessories",
    img3: require("../../images/lab/42.webp"),

  }],
  subcategory5:[{
    subid:122,
    title:"Laboratory sockets",
    img50: require("../../images/lab/43.webp"),

  }],
    bgimg:  categorybg2,
    button: "know More",
    button8:"EMERGENCY SHOWER & EYEWASH"
  },
  {
    id: 14,
    cid:14,
    dropname:"Laboratory Equipments ⏷",
    name: "Laboratory Equipments",
    img1: require("../../images/lab/44.png"),
    alink1:"/categorypage/123",
    img2: require("../../images/lab/45.webp"),
    alink2:"/categorypage/124",
    img3: require("../../images/lab/46.webp"),
    alink3:"/categorypage/125",

    // img4: require("../../images/lab/52.webp"),
    // img5: require("../../images/lab/53.png"),
    // img7: require("../../images/lab/54.png"),
    // img8: require("../../images/lab/55.webp"),
    // img9: require("../../images/lab/68.png"),
    cardImg:require("../../images/lab/45.webp"),
    cardDesc:"Equip your laboratory with state-of-the-art instruments and equipment. Our comprehensive selection of laboratory equipment includes cutting-edge technology for research, analysis, and experimentation.",
    content1:"Equip your laboratory with state-of-the-art instruments and equipment. Our comprehensive selection of laboratory equipment includes cutting-edge technology for research, analysis, and experimentation.",
    // content10:"A Ducted Fume Hood is a type of ventilation system that primarily functions to provide personnel protection against toxic fumes, vapors, and dust. This containment device is connected to a ducting where contaminants pass before being exhausted into the environment. Its secondary function includes protection against chemical spills, runaway reactions, and fires by acting as a physical barrier.",
    // content18:"Ductless fume cabinets are designed to protect from hazards such as fumes, vapours and flying glass. Teachers and educators in schools, colleges and university laboratories can be confident they are working in a safe environment. ",
    // content19:"This type of fume hood utilizes carbon or HEPA filtration to protect laboratory personnel from toxic chemical fumes, odors, and particles.",
    // content27:"A Biological Safety Cabinet (BSC), also known as a Biosafety Cabinet is mainly used for handling pathogenic biological samples or for applications that require a sterile work zone. A biological safety cabinet creates inflow and downflow of air that provides operator protection. The downflow air passes through an ULPA/HEPA filter and creates an ISO Class 3 work zone to protect samples from the risk of cross-contamination. The air exhausted also passes through an ULPA/HEPA filter prior to release to protect the environment.",
    // content28:"biosafety cabinets have a wide range of options from work trays, side panel construction to accessories. BSCs are designed with ergonomic and energy-saving features.",
    // content29:"To guarantee the biological safety cabinet's optimum performance these are tested and classified by 2 standards: EN 12469:2000 and NSF 49.",

    // process1:"Ducted Fumehood",
    // process2:"Ductless Fumehood",
    // process3:"Biology safety cabinet",
    subcategory:[{
      subid:123,
      title:"Ducted Fumehood",
      images:[{
        img1: require("../../images/lab/52.webp"),
        img2: require("../../images/lab/53.png"),
      }],
      description:"A Ducted Fume Hood is a type of ventilation system that primarily functions to provide personnel protection against toxic fumes, vapors, and dust. This containment device is connected to a ducting where contaminants pass before being exhausted into the environment. Its secondary function includes protection against chemical spills, runaway reactions, and fires by acting as a physical barrier.",

    }],
    subcategory2:[{
      subid:124,
      title:"Ductless Fumehood",
      images:[{
        img1: require("../../images/lab/54.png"),
        img2: require("../../images/lab/55.webp"),
      }],
      description:"Ductless fume cabinets are designed to protect from hazards such as fumes, vapours and flying glass. Teachers and educators in schools, colleges and university laboratories can be confident they are working in a safe environment. ",

    }],
    subcategory3:[{
      subid:125,
      title:"Biology safety cabinet",
      images:[{
        img9: require("../../images/lab/68.png"),
         imgbio:require("../../images/lab/72.jpg"),
         imgbio1:require("../../images/lab/73.jpg"),
         imgbio2:require("../../images/lab/74.jpg"),
         imgbio3:require("../../images/lab/75.jpg"),

      }],
      description:"A Biological Safety Cabinet (BSC), also known as a Biosafety Cabinet is mainly used for handling pathogenic biological samples or for applications that require a sterile work zone. A biological safety cabinet creates inflow and downflow of air that provides operator protection. The downflow air passes through an ULPA/HEPA filter and creates an ISO Class 3 work zone to protect samples from the risk of cross-contamination. The air exhausted also passes through an ULPA/HEPA filter prior to release to protect the environment.",
      list_items:[{
        content1:"biosafety cabinets have a wide range of options from work trays, side panel construction to accessories. BSCs are designed with ergonomic and energy-saving features.",
        content2:"To guarantee the biological safety cabinet's optimum performance these are tested and classified by 2 standards: EN 12469:2000 and NSF 49.",
      }],

    }],
    subcategory4:[{
      subid:126,
      title:"Horizondal Laminar Flow Cabinet",
      imgho:require("../../images/lab/76.jpg"),
      subtitle:"Applications",
      list_items:[{
       point1:" Plant Tissue Culture",
       point2:" Media Plate Preparation",
       point3:" Electronics Inspection",
       point4:" Electronics Inspection",
       point5:"	Medical Device Assembly",

      }],
      subtitle2:"Specifications",
      list_items2:[{
       point1:"	ULPA Filter",
       point2:"	ISO Class 3 work zone",
       point3:"	DC ECM blower for up to 70% energy savings",
       point4:"	Low noise at 55.8 dBA",
       point5:"	Fixed Sash",
       point6:"	Internal height: 2 ft",
       point7:"	Available sizes: 3 ft, 4 ft, 5 ft and 6ft",
      }],
      subtitle3:"High-Quality Construction",
      list_items3:[{
       point1:"	Stainless steel worktop",
       point2:"	Spill-retaining design will ensure containment of accidental liquid spills",
      }],
      subtitle4:"Quiet Operation",
      list_items4:[{
       point1:"	Comfortable low noise at 52 dBA",
       point2:"	Reduce fatigue and improve work concentration",
      }],
      subtitle5:"ULPA Filter",
      list_items5:[{
       point1:"	10x Filtration efficiency of HEPA filter Creates ISO Class 3 work zone instead of industry-standard ISO Class 5",

      }],
      subtitle6:"Energy-efficient",
      list_items6:[{
       point1:"	Powered by latest generation DC ECM blower, that is more efficient than legacy ECM and VFD motors",
       point2:"	70% Energy savings compared to AC motor",
       point3:"	Stable airflow, despite building voltage fluctuations & filter loading",

      }],
      subtitle7:"Isocide™ Antimicrobial Powder Coating",
      list_items7:[{
       point1:"	Eliminates 99.9% of surface bacteria within 24 hours of exposure",

      }],
    }],

    subcategory5:[{
      subid:127,
      title:"Vertical Laminar Flow Cabinet",
      imgve:require("../../images/lab/77.jpg"),
      subtitle:"Applications:",
      list_items:[{
        point1:"	Microbiology (non-pathogenic)",
        point2:"	Forensics",
        point3:"	Sterile product compounding",
      }],
      subtitle2:"Specifications:",
      list_items2:[{
        point1:"	ULPA Filter",
        point2:"	ISO Class 3 work zone",
        point3:"	DC ECM blower for up to 70% energy savings",
        point4:"	Low noise at 52.4 dBA",
        point5:"	Stainless steel side walls",
        point6:"	Fixed Sash",
        point7:"	Internal height: 2 ft",
        point8:"	Available sizes: 3 ft, 4 ft, 5 ft, 6ft",
      }],
      subtitle3:"High-Quality Construction",
      list_items3:[{
        point1:"	Stainless steel worktop",
        point2:"	Spill-retaining design will ensure containment of accidental liquid spills",
      }],
      subtitle4:"Quiet Operation",
      list_items4:[{
        point1:"	Comfortable low noise at 52 dBA",
        point2:"	Reduce fatigue and improve work concentration",
      }],
      subtitle5:"ULPA Filter",
      list_items5:[{
        point1:"	10x Filtration efficiency of HEPA filter Creates ISO Class 3 work zone instead of industry-standard ISO Class 5",
      }],
      subtitle6:"Energy-efficient",
      list_items6:[{
        point1:"	Powered by latest generation DC ECM blower, that is more efficient than legacy ECM and VFD motors",
        point2:"	70% Energy savings compared to AC motor",
        point3:"	Stable airflow, despite building voltage fluctuations & filter loading",

      }],
      subtitle7:"Isocide™ Antimicrobial Powder Coating",
      list_items7:[{
        point1:"	Eliminates 99.9% of surface bacteria within 24 hours of exposure",
      }],
    }],
    subcategory6:[{
      subid:128,
      title:"Safety storage cabinet",
      images:[{
        img1: require("../../images/lab/78.jpg"),
        img2: require("../../images/lab/79.jpg"),
        img3: require("../../images/lab/80.jpg"),
      }],
      subtitle:"CHEMICAL STORAGE CABINETS AND FLAMMABLE STORAGE CABINETS",
      list_items:[{
        point1:"Our wide range of safety storage cabinets is the result of listening to the customer’s needs and knowing the safety regulations in force. ",
        point2:"Chemical storage cabinets are designed and built for the safe storage of acids & corrosive chemicals.",
        point3:"Flammable safety cabinets tailored for the storage of flammable and combustible liquids, ensure maximum protection for both personnel and products."
      }],
      

    }],

    bgimg: require("../../images/lab/45.webp"),
    button: "know More",
  },
 
  {
    id: 3,
    cid: 3,
    dropname:"Kindergarten Furniture ",

    name: "Kindergarten Furniture",
    img1: require("../../images/lab/18.webp"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/18.c2e7886ae07706646b7d.webp",
    img2: require("../../images/lab/19.webp"),
    alink2:"https://smart-lab-flame.vercel.app/static/media/19.1cc0e1965ff139967b4a.webp",
    img3: require("../../images/lab/20.webp"),
    alink3:"https://smart-lab-flame.vercel.app/static/media/20.2a1783366b014e6a4a1d.webp",
    cardImg:require("../../images/cards/kindergarten1.jpg"),
    cardDesc:"Nurture young minds with our specially crafted kindergarten furniture. Our designs prioritize safety, comfort, and creativity to provide an ideal learning environment for children.",
    content1:"We offer a wide range of Kindergarten furniture which are vibrant, customised, and durable. Our furniture are guarantee to prolong children’s interest and participation in the classroom activities and ensure their joy and cheerfulness. ",
    contentbox1:"	Tables",
    contentbox2:"	Chairs",
    contentbox3:"	Storage",
    contentbox4:"	Cabinets",
    contentbox5:"	Interactive wall panels",
    contentbox6:"	Dining and library furniture",
    bgimg: require("../../images/lab/20.webp"),
    button: "know More",
  },
  {
    id: 4,
    cid: 4,
    dropname: "Educational Furniture",
    name: "Educational Furniture",
    img1: require("../../images/lab/21.webp"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/21.0b9303961cab77875b9b.webp",
    img2: require("../../images/lab/22.webp"),
    alink2:"https://smart-lab-flame.vercel.app/static/media/22.dd506520475ff3c4086d.webp",
    img3: require("../../images/lab/23.webp"),
    alink3:"https://smart-lab-flame.vercel.app/static/media/23.4362c03afc749b7dbff9.webp",
    img20: require("../../images/lab/65.png"),
    img21: require("../../images/lab/63.png"),
    img22: require("../../images/lab/64.png"),
    img23: require("../../images/lab/62.png"),
    cardImg:require("../../images/cards/Educationalfurniture.jpg"),
    cardDesc:"Foster learning excellence with our educational furniture solutions. We offer a wide range of classroom furniture, library furnishings, and interactive learning tools designed to inspire students and educators alike.",
    content1:"We are manufacturing Educational furniture with more comfortable and safe learning which give good working spaces for students and teachers.Our school furniture provides the best learning environment for children and supports the healthy and interactive growth of kids withoutcompromising comfort and safety",
    content2:"We are proving all types of classroom furniture like student desk, tables and chair, storage and cupboards, teacher table and chair.",
    content54:"Teacher’s Table : Our custom-made Teachers tables are available in a variety of design /colours and style to suit all class rooms",
    content4:"Standard sizes: 750/800/900/1000/1200/1500/1800 (W) x 600/750 (D) x 760mm (H) ",
    content55:"Teacher’s chair : Our verity of Teachers chairs is tall-backed, Providing superior comfort and support for the entire upper body.Executive chairs are an Icon – their height and quality convey authority",
    content56:"Student’s Table : Our custom-made Students tables are available in a variety of design /colors and style to suit all class rooms",
    content7:"Standard sizes: 750/800/900/1200 (W) x 600/750 (D) x 530/590/640/710/760mm (H)",
    content57:"Student’s chair : Our students chair is fresh and innovative collection that provides a harmonic sensation for different classroom",
    bgimg: categorybg4,
    button: "know More",
  },
  {
    id: 5,
    cid: 5,
    name: "Office Furniture",
    dropname: "Office Furniture",
    img1: require("../../images/lab/24.webp"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/24.7eb28ca3784f13aa5350.webp",
    img2: require("../../images/lab/25.webp"),
    alink2:"https://smart-lab-flame.vercel.app/static/media/25.df8187fd2fe00f0ba41c.webp",
    img3: require("../../images/lab/26.webp"),
    alink3:"https://smart-lab-flame.vercel.app/static/media/26.eccd135cecc070859f4a.webp",
    cardImg:require("../../images/cards/officefurniture.jpg"),
    cardDesc:"Elevate your workplace with our ergonomic and stylish office furniture. From modern workstations to ergonomic chairs, our office furniture solutions enhance productivity and aesthetics in your workspace.",
    content1:"We offer a complete range of customized and innovative office furniture with best quality and material. Our furniture that looks classy, efficient makes stylish office that inspires trust in client.",
    contentbox1:"	Workstations",
    contentbox2:"	Conference table",
    contentbox3:"	Open desk",
    contentbox4:"	Cupboards",
    contentbox5:"	storage cabinets",
    contentbox6:"	Filing cabinets ",
    contentbox7:"	Drawer unit",
    contentbox8:" tables,chairs",
    bgimg: require("../../images/lab/26.webp"),
    button: "know More",
  },
  {
    id: 6,
    cid: 6,
    dropname: "HPL Lockers",
    name: "HPL Lockers",
    alink1:"https://smart-lab-flame.vercel.app/static/media/27.1bd7de89518aeafe7cd3.webp",
    img1: require("../../images/lab/27.webp"),
    alink2:"https://smart-lab-flame.vercel.app/static/media/28.04f41fa253b7e5b8f080.webp",
    img2: require("../../images/lab/28.webp"),
    alink3:"https://smart-lab-flame.vercel.app/static/media/29.8f4a2e3bde3f4318530e.webp",

    img3: require("../../images/lab/29.webp"),
    cardImg:require("../../images/cards/LOCKER.jpg"),
    cardDesc:"Ensure security and organization with our High-Pressure Laminate (HPL) lockers. These robust lockers are suitable for various applications, including schools, gyms, and workplaces.",
    content1:"We are providing highly durable and best quality HPL lockers for Educational institutions, Gym, Hospitals, shopping malls, Hotels and swimming pools etc.",
    content2:"	HPL lockers : Standard size: 300mmWx500mmDx1850mmH ",
    content3:"	Carcass made of 13mm thick moisture resistant High Pressure Compact Laminate (HPL) with black core.",
    content4:"	Cabinet divider panels & shelves from 13mm thick moisture resistant High Pressure Compact Laminate (HPL) with black core.",
    content5:"	Doors from 13mm thick moisture resistant High Pressure Compact Laminate (HPL) with black core.",
    content6:"	Cabinet back panel from 6mm thick melamine Faced MDF.",
    content7:"	All connecting screws in Galvanized iron material.",
    content8:"	Base frame from 40x40x2mm Epoxy powder coated rigid frame works. (If Necessary).",
    content10:"	Exceptional self-supporting properties.",
    content11:"	Greater dimensional stability and flatness",
    content12:"	Resistance to abrasion",
    content13:"	Resistance to water",
    content14:"	Resistance to fire",
    content15:"	Excellent hygiene properties",
    content16:"	Resistance to harmful chemicals and organic solvents",
    content17:"	Simple and quick assembly without the need for edging and adhesive",
    process1:"PRODUCT DESCRIPTION",
    bgimg: categorybg6,
    button: "know More",
  },
  {
    id: 7,
    cid: 7,
    name: "Canteen Furniture",
    dropname: "Canteen Furniture",
    img1: require("../../images/lab/30.webp"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/30.2d879fcfa3fe18fadc5d.webp",
    alink2:"https://smart-lab-flame.vercel.app/static/media/11.d1afc9c42cc47b5d1bd7.webp",
    alink3:"https://smart-lab-flame.vercel.app/static/media/03.e35c62678e4b85da05b8.webp",
    img2: require("../../images/lab/11.webp"),
    img3: require("../../images/lab/03.webp"),
    cardImg:require("../../images/lab/03.webp"),
    cardDesc:"Enhance dining experiences with our canteen furniture. We provide aesthetically pleasing and durable furnishings for cafeteria and canteen areas in schools, offices, and other institutions.",
    content1:"We are engaged in offering a broad range of superior quality canteen furniture. These modern canteen furniture are highly appreciated for its unique and innovating design. A great canteen is not only a great place to hang out but it can be a platform for the sharing of ideas, free flow of creativity, and deep conversations. ",
    contentbox1:"	Canteen Table",
    contentbox2:"	Canteen Benches",
    contentbox3:" 	Canteen Chairs ",
    contentbox4:"	Buffet counters",
    contentbox5:" 	Heavy duty shelving",
    contentbox6:" 	Heavy duty wash through sink",
    contentbox7:"	Stainless steel Preparation table",
    contentbox8:"	Plate collection trolleys",
    contentbox9:"	Preparation room Sinks units",
    bgimg:require("../../images/lab/03.webp"),
    button: "know More",
  },
  {
    id: 8,
    cid: 8,
    dropname: "Hospital Furniture ⏷",
    name: "Hospital Furniture",
    img1: require("../../images/lab/31.webp"),
    alink1:"/categorypage/114",
    img2: require("../../images/lab/32.webp"),
    alink2:"/categorypage/115",
    img3: require("../../images/lab/33.webp"),
    alink3:"/categorypage/116",
    img24: require("../../images/lab/31.webp"),
    img25: require("../../images/lab/66.webp"),
    img26: require("../../images/lab/32.webp"),
    img27: require("../../images/lab/33.webp"),
    img28: require("../../images/lab/67.webp"),
    cardImg:require("../../images/lab/33.webp"),
    cardDesc:"Provide comfort and care to patients with our hospital furniture solutions. We offer a range of durable and hygienic healthcare furnishings designed to meet the unique requirements of medical facilities.",
    contenthos1:"	Hospital Lab Furniture ",
    content1:"	Laboratory furniture is designed to meet the needs of the modern laboratory where flexibility and adjustability are essential       ",
    content2:"	Laboratory furniture is designed to meet the needs of the modern laboratory where flexibility and adjustability are essential       ",
    hospital:"Provide comfort and care to patients with our hospital furniture solutions. We offer a range of durable and hygienic healthcare furnishings designed to meet the unique requirements of medical facilities.",
    contenthos3:"	Nurse station",
    content4:" Our Nurse station can be customized to support whatever your technology, work process, and staffing needs are.",
    contenthos5:" Pharmacy ",
    content6:"	We are providing the best pharmacy-related design, including pharmacy interiors, Pharmacy shelving, pharmacy reception counters, drug collection and drugs receiving counters.",
    bgimg: require("../../images/lab/31.webp"),
    button: "know More",
    subcategory:[
      {
      subid:114, 
      title:"Hospital Lab Furniture",
      images:[{
        img24: require("../../images/lab/31.webp"),
        img25: require("../../images/lab/66.webp"),
      }],
      description:"	Laboratory furniture is designed to meet the needs of the modern laboratory where flexibility and adjustability are essential",
    }
  ],
    subcategory2:[{
      subid:115, 
      title:"Nurse station",
      images:[{
        img26: require("../../images/lab/32.webp"),

      }],
      description:" Our Nurse station can be customized to support whatever your technology, work process, and staffing needs are.",
    }
  ],
    subcategory3:[
      {
      subid:116, 
      title:"Pharmacy",
      images:[{
        img27: require("../../images/lab/33.webp"),
        img28: require("../../images/lab/67.webp"),
      }],
      description:"We are providing the best pharmacy-related design, including pharmacy interiors, Pharmacy shelving, pharmacy reception counters, drug collection and drugs receiving counters.",
    }
  ],
    button4: "Hospital Lab Furniture",
    button5: "Nurse station ",
    button6: "Pharmacy",
  },
  {
    id: 9,
    cid: 9,
    dropname: "Toilet Cubicle",
    name: "Toilet Cubicle",
    img1: require("../../images/lab/34.webp"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/34.843708d3f129a3dfe4df.webp",
    alink3:"https://smart-lab-flame.vercel.app/static/media/36.d06faed2582e547e7472.webp",

    // img2: require("../../images/lab/35.webp"),
    img3: require("../../images/lab/36.webp"),
    alink2:"",
    img29: require("../../images/lab/71.png"),
    img30: require("../../images/lab/35.webp"),
    cardImg:require("../../images/lab/35.webp"),
    cardDesc:"We offer 100% moisture resistant, robust and durable Toilet cubicle. This is most often used in commercial offices, Crafted to withstand high traffic and the harshest conditions, our cubicles maintain their integrity,",
    content1:"We offer 100% moisture resistant, robust and durable Toilet cubicle. This is most often used in commercial offices, public buildings, commercial offices, public buildings, shopping malls, hospitals, universities, schools, gymnasiums and sporting facilities.",
    content2:" Panels and doors in 12mm thick moisture resistant, weather resistant and fire retardant, High Pressure compact Laminate with Melamine facing on both sides.",
    content3:"	The panel to panel and panel to wall connections will be securely fixed by stainless steel L-Bracket.",
    content4:"	Door panel securely fixed with stainless steel Head rail.",
    content5:"	Standard door opening / door size 700mm.",
    content6:"	Each door will be fixed with stainless steel finished door lock complete with cover plate, integral red/green indicator.",
    content7:"	Door will be fitted with stainless steel coat hook.",
    content8:"	All connecting screws/bolts in high corrosion resistant stainless-steel material brush finish.",
    content10:"PRODUCT COMPOSITION",
    content11:"Decorative surface papers impregnated with melamine resins are pressed over Kraft paper core sheets, impregnated with thermosetting resins. These papers are then cured at pressure of 7mpa and temperature of 150°C in which the resins polycondense.The finished sheets are finally trimmed and ready for use",
    content12:"PRODUCT DESCRIPTION",
    content13:"	Exceptional self-supporting properties.",
    content14:"	Greater dimensional stability and flatness",
    content15:"	Resistance to abrasion.",
    content16:"	Resistance to water",
    content17:"	Excellent hygiene properties",
    content17a:"	Resistance to harmful chemicals and organic solvents",
    content17b:"	Simple and quick assembly without the need for edging and adhesive",
    // subcategory:[{
    //   subid:117,
    //   title:"12mm Thick High Pressure Compact Laminate (HPL)",
    //   images:[{
    //     img30: require("../../images/lab/35.webp"),
    //   }],
    //   list_items:[{
    //     content10:"PRODUCT COMPOSITION",
    //     content11:"Decorative surface papers impregnated with melamine resins are pressed over Kraft paper core sheets, impregnated with thermosetting resins. These papers are then cured at pressure of 7mpa and temperature of 150°C in which the resins polycondense.The finished sheets are finally trimmed and ready for use",
    //     content12:"PRODUCT DESCRIPTION",
    //     content13:"	Exceptional self-supporting properties.",
    //     content14:"	Greater dimensional stability and flatness",
    //     content15:"	Resistance to abrasion.",
    //     content16:"	Resistance to water",
    //     content17:"	Excellent hygiene properties",
    //     content17a:"	Resistance to harmful chemicals and organic solvents",
    //     content17b:"	Simple and quick assembly without the need for edging and adhesive",
    //   }]
    // }],

    process1:"12mm Thick HIGH PRESSURE COMPACT LAMINATE (HPL)",
    bgimg: require("../../images/lab/35.webp"),
    button: "know More",
    button7:"12mm Thick HIGH PRESSURE COMPACT LAMINATE (HPL)"
  },
  {
    id: 10,
    cid: 10,
    dropname: "Library Furniture",
    name: "Library Furniture",
    img1: require("../../images/lab/04.webp"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/04.caa7ca913c2d34ec509d.webp",
    img2: require("../../images/lab/06.webp"),
    alink2:"https://smart-lab-flame.vercel.app/static/media/06.54a077514c7ba65833ed.webp",
    img3: require("../../images/lab/08.webp"),
    alink3:"https://smart-lab-flame.vercel.app/static/media/08.ac4292f15426d89a4148.webp",
    cardImg:require("../../images/lab/08.webp"),
    cardDesc:"Foster a love for reading and learning with our library furniture. We offer an array of bookshelves, seating, and study areas to create inviting library spaces. passion for literature and knowledge with our library furnishings.",
    content1:"We specialize in manufacturing a wide range of library furniture as per the client requirements with high quality and trendy designs. Our library furniture is capable of transforming the very look and vibe of your library.",
    contentbox1:"	Book stacks",
    contentbox2:"	Book Racks",
    contentbox3:"	Book stands",
    contentbox4:"	Book browsers",
    contentbox5:"	Mobile tray storage unit ",
    contentbox6:"	Magazine Rack",
    contentbox7:"	Librarian desk",
    contentbox8:"	Reader’s table",
    contentbox9:"	Display shelf",
    contentbox10:"	Filing cabinets",
    contentbox11:"	Collaboration desk ",
    contentbox12:"	Tables, Chairs ",
    bgimg: require("../../images/lab/08.webp"),
    button: "know More",
  },
  {
    id: 11,
    cid: 11,
    dropname: "Renovation Services",
    name: "Renovation Services",
    // img1: require("../../images/lab/15.webp"),
    imgRen: require("../../images/lab/37.webp"),
    alinkren:"https://smart-lab-flame.vercel.app/static/media/37.a2c36094dabb424d458a.webp",
    // img3: require("../../images/lab/22.webp"),
    cardImg:require("../../images/lab/15.webp"),
    cardDesc:"Revitalize your space with our renovation services. Whether it's a complete makeover or targeted improvements, our renovation experts bring new life to your surroundings.",
    content1:"We offer all types renovation services to Interior works in office, school, hotel, hospital and residents. Also do the renovation works of laboratories in educational institution.  Our design helps to effectively optimize the space in your place, making the best use of the available space with modern and stylish.",
    bgimg: require("../../images/lab/15.webp"),
    button: "know More",
  },
  {
    id: 12,
    cid: 12,
    dropname: "Kitchen Furniture",
    name: "Kitchen Furniture",
    imgKit: require("../../images/lab/38.webp"),
    imgKit1: require("../../images/lab/39.webp"),
    // img3: require("../../images/lab/40.webp"),
    cardImg:require("../../images/lab/38.webp"),
    cardDesc:"We are manufacturing high quality and durable Kitchen Furniture.  Whether the kitchen space is in square or rectangle, big or small, we have talented designers to offer the best intelligent space management.",
    content1:"We are manufacturing high quality and durable Kitchen Furniture.  Whether the kitchen space is in square or rectangle, big or small, we have talented designers to offer the best intelligent space management.",
    contenth4:"We are providing ",
    contentbox1:"	Kitchen Cabinet",
    contentbox2:"	Kitchen Island",
    contentbox3:"	Bar Stools and Chairs",
    contentbox4:"	Baker Racks",
    contentbox5:"	Kitchen Buffets",
    contentbox6:"	Sink and Faucet",
    bgimg:require("../../images/lab/38.webp"),
    button: "know More",
  },

  {
    id: 13,
    cid: 13,
    dropname: "Interior Works",
    name: "Interior Works",
    img1: require("../../images/lab/15.webp"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/15.fa56b4504e4832b2814f.webp",
    img2: require("../../images/lab/16.webp"),
    alink2:"https://smart-lab-flame.vercel.app/static/media/16.7859e621f8cdf74060ac.webp",
    img3: require("../../images/lab/17.webp"),
    alink3:"https://smart-lab-flame.vercel.app/static/media/17.388c652f7e025ced8494.webp",
    cardImg:require("../../images/cards/interiorworks.jpg"),
    cardDesc:"Transform your space into a work of art with our expert interior design services. From conceptualization to execution, we create captivating interiors for offices and residential spaces,",
    content1:"We provide all kind of Interior design services. We offer a complete set of services in the field of designing, furnishing and installing.Our interior design work process is designed to create a beautiful and functional space that meets your unique needs and preferences. We complete our projects within a timeframe to ensure that you can enjoy the new space as soon as possible.",
    contentbox1:"	Wall Panelling",
    contentbox2:"	Wooden Flooring",
    contentbox3:"	False Ceiling ",
    contentbox4:"	All types of furniture",
    bgimg: categorybg1,
    button: "know More",
  },
 
  {
    id: 15,
    cid: 15,
    dropname: "Reception Counters",
    name: "Reception Counters",
    img1: require("../../images/lab/69.webp"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/69.81ad621dc3f414301d02.webp",
    img2: require("../../images/lab/32.webp"),
    alink2:"https://smart-lab-flame.vercel.app/static/media/32.1b3ac1eb14bda0bb55af.webp",
    img3: require("../../images/lab/70.webp"),
    alink3:"https://smart-lab-flame.vercel.app/static/media/70.d734c3bac0f04bdcedfb.webp",
    cardImg:require("../../images/lab/32.webp"),
    cardDesc:"We have expertise to provide you with customized services of manufacturing and installing a beautiful Reception desk meeting your specifications.Our skilled team is dedicated to turning your vision into reality ",
    content1:"We have expertise to provide you with customized services of manufacturing and installing a beautiful Reception desk meeting your specifications. ",
    contenth4:"We are providing reception desk with following features",
    contentbox1:"	Plenty of Storage Space.",
    contentbox2:"	A Split-Level Reception Desk.",
    contentbox3:"	Make Your Reception Desk Focal Point.",
    contentbox4:"	Separate the Reception and Visitor Area.",
    contentbox5:"	Workplace Protection Products.",
    bgimg: require("../../images/lab/32.webp"),
    button: "know More",
  },
  {
    id: 16,
    cid: 16,
    dropname: "Lab Stools & Chairs",
    name: "Lab Stools & Chairs",
    stoolh:"A full range of Lab Stools and Chairs that suit every purpose & setting",
    img1: require("../../images/lab/81.jpg"),
    alink1:"https://smart-lab-flame.vercel.app/static/media/81.03d53a4aa95d9ef5a407.jpg",
    img2: require("../../images/lab/82.jpg"),
    alink2:"https://smart-lab-flame.vercel.app/static/media/81.03d53a4aa95d9ef5a407.jpg",
    img3: require("../../images/lab/83.jpg"),
    alink3:"https://smart-lab-flame.vercel.app/static/media/83.da82265f3e6f33374b4c.jpg",
    imgchair: require("../../images/lab/84.jpg"),
    alink4:"https://smart-lab-flame.vercel.app/static/media/84.1917f0f18d0de4119a8c.jpg",
    firsth5:"SL-01",
    secondh5:"SL-02",
    thirdh5:"SL-03",
    // fourthh5:"LABORATORY SOCKETS",
    cardImg:require("../../images/lab/84.jpg"),
    cardDesc:"These seating solutions often come with adjustable height features to accommodate various workspaces and tasks. The design prioritizes comfort during long hours of work, ",
    bgimg:require("../../images/lab/84.jpg"),
    button: "know More",
  },
];
